<template>
  <div class="order-page">
    <div class="order-header">
      <div class="order-header-tabs">
        <van-tabs v-model="tabActive">
          <van-tab title="服务进度" name="order" :to="'/order/' + active" replace></van-tab>
          <van-tab title="物流进度" name="logistics" to="/logistics" replace></van-tab>
        </van-tabs>
      </div>
      <div class="order-tab-container" v-if="tabActive === 'order'">
        <van-tabs v-model="active">
          <van-tab title="已提交" name="submit" to="/order/submit" replace></van-tab>
          <van-tab title="已派工" name="dispatch" to="/order/dispatch" replace></van-tab>
          <van-tab title="服务中" name="inservice" to="/order/inservice" replace></van-tab>
          <van-tab title="已完成" name="complete" to="/order/complete" replace></van-tab>
          <van-tab title="已取消" name="cancel" to="/order/cancel" replace></van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="order-page-main">
      <router-view />
    </div>
    <ServiceBtn />
  </div>
</template>

<script>
  import { Tabs, Tab } from 'vant'
  import ServiceBtn from '@/components/service-btn'

  export default {
    components: {
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      ServiceBtn
    },
    watch: {
      $route(val) {
        const path = val.path.split('/')
        this.tabActive = path[1]
        if (path[2]) {
          this.active = path[2]
        }
      }
    },
    created() {
      const path = this.$route.path.split('/')
      this.tabActive = path[1]
      if (path[2]) {
        this.active = path[2]
      }
    },
    data() {
      return {
        path: '',
        tabActive: '',
        active: '',
      }
    }
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>
<style lang="less">
  .order-page {
    .van-tabs__line {
      background-color: #186AFF;
      box-shadow: 0 0.2667vw 0.2667vw rgba(24,106,255,.1) 
    }
  }
  .order-header-tabs {
    .van-tabs__line {
      width: 8vw;
    }
    .van-tab {
      font-size: 4vw; 
      font-weight: bold;
      color: #4C4C57;
    }
    .van-tab--active {
      color: #1C1F20
    }
  }
  .order-tab-container {
    .van-tabs__line {
      width: 5.8667vw;
    }
    .van-tab {
      font-size: 3.2vw;
      color: #9C9DAA;
      padding: 0 4.6667vw !important;
    }
    .van-tab--active {
      color: #2F3437;
      font-size: 3.4667vw;
    }
  }
</style>